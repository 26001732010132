<template>
 <div class="home-page" v-loading="mainLoading">
    <div class="top-page">
      <div class="logo">
        <div v-if="!logoVisible" style="width:80%;height:auto;"></div>
        <div v-else>
          <img v-if="!logoPath" :src="Logo" alt="Logo" @load="handleLoad" style="width:80%;">
          <img v-else :src="logoPath" alt="Logo" style="width:80%" >
        </div>
      </div>
      <div class="date-info">
        {{ currentDate }}
      </div>
    </div>
  <div class="login-page">
    <div class="main">
      <div class="big-logo">
        <img :src="BigLogo" />
      </div>
      <!-- <h1 @click="login">This is an about page</h1> -->
      <div class="mains">
        <div v-if="!titleVisible" class="tips" style="opacity: 0;height:auto;">.</div>
        <div v-else class="tips" :class="{'tips-active':titleVisible}">{{title}}</div>
        <div class="tab">
          <div
            class="item-user"
            @click="LoginType = 'User'"
          >
            手机登录
            <el-divider></el-divider>
          </div>
          <div
            class="item-wx"
            @click="clickWx"
          >
            微信扫码
            <el-divider></el-divider>
          </div>
        </div>
        <div class="login-item">
          <div class="User">
            <div class="input-item">
              <input
                type="text"
                placeholder="请输入手机号"
                v-model="info.phone"
              />
              <i class="el-icon-user"></i>
            </div>
            <div class="input-item">
              <input
                type="password"
                placeholder="请输入密码"
                v-model="info.password"
                @keyup.enter="login('ap')"
              />
              <i class="el-icon-lock"></i>
            </div>
            <div style="margin-top: 20px">
              <el-checkbox
                v-model="rememberPassword"
              >记住密码
              </el-checkbox>
              <el-checkbox
                v-model="rememberLogin"
                @change="changeRememberLogin"
              >15天免登录
              </el-checkbox>
            </div>
            <el-button
              class="user-button"
              style="margin-top:20px"
              :loading="loginLoading"
              @click="login('ap')"
              >立即登录</el-button
            >
          </div>
          <div class="QrCode">
            <div id="login_qrcode"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-page">
    <div class="beian">
      <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19147145号</a>
      <span style="margin-left:15px">Copyright@2020 All Rights Reserved</span>
    </div>
  </div>
 </div>
</template>

<script>
import Logo from "@/assets/show_logo.png";
import BigLogo from "@/assets/big_logo.gif";
import { loginApi } from "@/api/login";
export default {
  data() {
    return {
      LoginType: "User",
      info: {
        phone: "",
        password: "",
      },
      loginLoading: false,
      Logo,
      BigLogo,
      currentDate: '',
      logoVisible: false,
      logoPath: "",
      titleVisible: false,
      title: "欢迎使用霄鸟云贸易管理系统",
      domain: "https://imgs.toys-trades.com/",
      rememberPassword: false,
      rememberLogin: false,
      mainLoading: false,
    };
  },
  created() {
    if (this.$route.query.code) {
      this.login("wx", { code: this.$route.query.code });
    }
    if (this.$route.query.checkRememberLogin) {
      this.rememberLogin = true;
    }
  },
  mounted() {
    let logout = new URLSearchParams(window.location.search).get("logout");
    let isNotReLogin = new URLSearchParams(window.location.search).get("isNotReLogin");
    if (logout || isNotReLogin) {
      localStorage.removeItem('isRememberLogin');
      localStorage.removeItem('isWxRememberLogin');
      localStorage.removeItem('loginExpiry');
      localStorage.removeItem('isRememberPassword');
      localStorage.removeItem('loginPhone');
      localStorage.removeItem('encodedPassword');
    }
    this.getLogoInfo();
    this.getLoginInfo();
    this.wxLoginInit();
    this.getCurrentDate();
  },
  methods: {
    login(type, params = {}) {
      if (!this.rememberPassword) {
        localStorage.removeItem('isRememberPassword');
        localStorage.removeItem('loginPhone');
        localStorage.removeItem('encodedPassword');
      }
      this.loginLoading = true;
      loginApi()
        .login({
          type: type,
          params: JSON.stringify(params) == "{}" ? this.info : params,
        })
        .then((res) => {
          window.location.href = res.url;
          if (res.code == 0) {
            if (type == "ap") {
              if (this.rememberPassword) {
                let encodedPassword = this.encodePassword(this.info.password);
                localStorage.setItem('isRememberPassword', true);
                localStorage.setItem('isRememberLogin', false);
                localStorage.setItem('loginPhone', this.info.phone);
                localStorage.setItem('encodedPassword', encodedPassword);
              } else {
                localStorage.removeItem('isRememberPassword');
                localStorage.removeItem('loginPhone');
                localStorage.removeItem('encodedPassword');
              }
              if (this.rememberLogin) {
                let encodedPassword = this.encodePassword(this.info.password);
                localStorage.setItem('isRememberLogin', true);
                if (!this.rememberPassword) {
                  localStorage.setItem('isRememberPassword', false);
                }
                localStorage.setItem('isWxRememberLogin', false);
                localStorage.setItem('loginPhone', this.info.phone);
                localStorage.setItem('encodedPassword', encodedPassword);
                let expiryDate = new Date();
                expiryDate.setDate(expiryDate.getDate() + 15);
                localStorage.setItem('loginExpiry', expiryDate.toISOString());
              } else {
                localStorage.removeItem('isRememberLogin');
                localStorage.removeItem('loginExpiry');
              }
            } else if (type == "wx") {
              if (this.rememberLogin) {
                localStorage.setItem('isWxRememberLogin', true);
                localStorage.setItem('isRememberLogin', false);
                localStorage.setItem('isRememberPassword', false);
                localStorage.setItem('loginPhone', res.phone);
                localStorage.setItem('encodedPassword', res.pwd);
                let expiryDate = new Date();
                expiryDate.setDate(expiryDate.getDate() + 15);
                localStorage.setItem('loginExpiry', expiryDate.toISOString());
              } else {
                localStorage.removeItem('isWxRememberLogin');
                localStorage.removeItem('loginExpiry');
                localStorage.setItem('isRememberLogin', false);
                localStorage.setItem('isRememberPassword', false);
                localStorage.removeItem('loginPhone');
                localStorage.removeItem('encodedPassword');
              }
            }
          }
        })
        .finally(() => {
          this.loginLoading = false;
        });
    },
    wxLoginInit(checkRememberLogin = false) {
      new window.WxLogin({
        id: "login_qrcode",
        appid: "wx1cd8c26934730cc5",
        scope: "snsapi_login",
        // redirect_uri: url,
        redirect_uri: checkRememberLogin ? encodeURIComponent("https://xnadmin.85804669.com?checkRememberLogin=true") : encodeURIComponent("https://xnadmin.85804669.com"),
        state: Math.ceil(Math.random() * 1000),
        style: "black",
        href:
          "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30NCi5pbXBvd2VyQm94IC5pbmZvIHtkaXNwbGF5OiBub25lO30NCi5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0NCmJvZHksaHRtbCB7aGVpZ2h0OiAzMDBweDt9",
      });
    },
    clickWx() {
      this.LoginType = "QrCode";
      this.wxLoginInit();
    },
    getCurrentDate() {
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let date = now.getDate();
      let dayOfWeek = ['日', '一', '二', '三', '四', '五', '六'][now.getDay()];
      this.currentDate = `今天是 ${year} 年 ${month} 月 ${date} 日 星期${dayOfWeek}`;
    },
    getLogoInfo() {
      let token = this.$route.query.param;
      if (token) {
        loginApi()
          .getLogoInfo({
            token,
          })
          .then((res) => {
            let data = res.data;
            if (data["logo_path"]) {
              this.logoPath = this.makeImageUrl(data["logo_path"]);
            }
            if (data["title"]) {
              this.title = `欢迎使用${data["title"]}贸易管理系统`;
            }
          })
          .finally(() => {
            this.logoVisible = true;
            this.titleVisible = true; 
          });
      } else {
        this.logoVisible = true;
        this.titleVisible = true; 
      }
    },
    makeImageUrl(image) {
      let timestamp = new Date().getTime();
      return `${this.domain}${image}?x-oss-process=image/resize,m_lfit,h_60,w_180&timestamp=${timestamp}`;
    },
    handleLoad() {
      this.$nextTick(() => {
        const img = this.$el.querySelector('img');
        if (img) {
          img.style.opacity = 1;
        }
      });
    },
    getLoginInfo() {
      let isRememberPassword = localStorage.getItem('isRememberPassword');
      if (isRememberPassword === 'true') {
        this.rememberPassword = true;
        this.info.phone = localStorage.getItem('loginPhone') || '';
        let encodedPassword = localStorage.getItem('encodedPassword');
        let decryptedPassword = this.decodePassword(encodedPassword);
        this.info.password = decryptedPassword;
      }
      let isRememberLogin = localStorage.getItem('isRememberLogin');
      let isWxRememberLogin = localStorage.getItem('isWxRememberLogin');
      if (isRememberLogin === 'true' || isWxRememberLogin === 'true') {
        this.rememberLogin = true;
        let loginExpiry = localStorage.getItem('loginExpiry');
        let expiryDate = new Date(loginExpiry);
        if (expiryDate > new Date()) {
          this.info.phone = localStorage.getItem('loginPhone') || '';
          let encodedPassword = localStorage.getItem('encodedPassword');
          let decryptedPassword = this.decodePassword(encodedPassword);
          this.info.password = decryptedPassword;
          setTimeout(() => {
            this.mainLoading = true;
          }, 50);
          loginApi()
            .login({
              type: 'ap',
              params: this.info,
            })
            .then((res) => {
              window.location.href = res.url;
            })
            .catch(() => {
              localStorage.removeItem('loginExpiry');
              localStorage.removeItem('loginPhone');
              localStorage.removeItem('encodedPassword');
            })
            .finally(() => {
              setTimeout(() => {
                this.mainLoading = false;
              }, 1500);
            });
        } else {
          localStorage.removeItem('isRememberLogin');
          localStorage.removeItem('isWxRememberLogin');
          localStorage.removeItem('loginExpiry');
          localStorage.removeItem('loginPhone');
          localStorage.removeItem('encodedPassword');
        }
      }
    },
    encodePassword(password) {
      return btoa(password);
    },
    decodePassword(password) {
      return atob(password);
    },
    changeRememberLogin(value) {
      if (value) {
        let checkRememberLogin = true;
        this.wxLoginInit(checkRememberLogin);
      } else {
        let checkRememberLogin = false;
        this.wxLoginInit(checkRememberLogin);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  display: flex;
  flex: 1;
  height: 100vh; 
  width: 100%;
  flex-direction: column;
  overflow: hidden;
}
.top-page {
  width: 100%;
  height: 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    margin-left: 12%;
  }
  .date-info {
    margin-right: 14%;
    color: #39393b;
    font-family: "Microsoft YaHei";
  }
}
.login-page {
  display: flex;
  flex: 1;
  width: 100%;
  height: 90%;
  min-width: 1230px;
  // background: url("../assets/background-img.png") no-repeat top right;
  background-color: #00a0fc;
  background-size: 543px;
  .main {
    display: flex;
    margin: auto;
    height: 638px;
    min-width: 1230px;
    max-width: 2200px;
    background: #ffffff;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    .big-logo {
      img {
        display: block;
        justify-content: center;
        align-items: center;
      }
      flex: 0 auto;
      padding: auto;
      padding-left: 30px;
    }
    .mains {
      flex: 1;
      padding: 90px 15px;
      .tips {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 500;
        color: #5962A7;
        opacity: 0;
        transition: opacity 1s ease-in;
      }
      .tips-active {
        opacity: 1;
        height: auto;
      }
      .logo {
        height: 60px;
        background: url("../assets/login_logo.png") no-repeat center;
      }
      .tab {
        border-bottom: 1px solid #eeeeee;
        padding-top: 50px;
        display: flex;
        .item-user {
          font-size: 20px;
          line-height: 40px;
          font-weight: bold;
          color: #5962A7;
          text-align: center;
          flex: 1.5 auto;
        }
        .item-wx {
          font-size: 20px;
          line-height: 40px;
          font-weight: bold;
          color: #5962A7;
          text-align: center;
          flex: 1.3 auto;
          cursor: pointer;
        }
      }
     .login-item {
      display: flex;
      .User {
        flex: 1;
        text-align: center;
        padding-top: 20px;
        padding-right: 80px;
        padding-left: 20px;
        .input-item {
          flex: 1;
          position: relative;
          padding-top: 32px;
          i {
            position: absolute;
            left: 20px;
            color: #999999;
            line-height: 56px;
            font-size: 20px;
          }
          input {
            padding: 0 50px;
            outline: none;
            border: 0px;
            max-width: 20vw;
            height: 56px;
            line-height: 56px;
            background: rgba(244, 244, 244, 1);
            border-radius: 28px;
            font-size: 20px;
          }
        }
        .user-button {
          width: 100%;
          max-width: 25vw;
          height: 56px;
          margin-top: 42px;
          padding: 0;
          line-height: 56px;
          background: #00a0fc;
          border-radius: 28px;
          font-size: 20px;
          color: #fff;
          cursor: pointer;
        }
      }
      .QrCode {
        flex: 1;
        justify-content: center;
        height: 12vh;
        margin-right:20px;
        div {
          display: flex;
          justify-content: center;
        }
      }
     }
    }
  }
}
.bottom-page {
  width: 100%;
  height: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .beian {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px auto;
    font-size: 15px;
    color: #4b4b4d;
    font-family: "Microsoft YaHei";
    a {
      color: inherit;
      text-decoration: none;
    }
    a:hover {
      color: #00a0fc;
    }
  }
}
.el-divider {
  margin-top: -3px;
  width: 40%;
  background-color: #00a0fc;
  border-width: 15px 0;
  border-bottom: 2px solid #00a0fc;
  margin: 0 auto;
}
</style>
